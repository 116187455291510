body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.map-wrap {
  position: relative;
  width: 100%;
  height: calc(100vh - 60px);  /* calculate height of the screen minus the heading */
}

/*Legend specific*/

#legend-container {
  background: rgba(255, 255, 255, 0.8);
}

.legend-item {
  min-width: 40px !important;
}





